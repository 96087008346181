var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "条件设置",
            width: "700px",
            visible: _vm.visiable,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content-wrap",
              attrs: { id: "content-wrap" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "60px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则名称" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-left": "12px" },
                              attrs: { span: 4 },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.form.enableFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "enableFlag", $$v)
                                    },
                                    expression: "form.enableFlag",
                                  },
                                },
                                [_vm._v("启用")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { staticClass: "border-name" }),
                  _c("div", { staticClass: "mail-dd" }, [
                    _c("div", { staticClass: "m-tit" }, [_vm._v("邮件到达时")]),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.fjrChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fjrChecked", $$v)
                              },
                              expression: "form.fjrChecked",
                            },
                          },
                          [_vm._v("发件人")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.fjrChecked,
                                expression: "form.fjrChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      "popper-class": "z-select-cls",
                                    },
                                    model: {
                                      value: _vm.form.fjrOper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "fjrOper", $$v)
                                      },
                                      expression: "form.fjrOper",
                                    },
                                  },
                                  _vm._l(_vm.getOptions(), function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticClass: "filed-item" },
                                [
                                  _c("span", { staticClass: "label" }),
                                  _c(
                                    "div",
                                    { staticClass: "input-wrapper" },
                                    [
                                      _c("input-tag", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.contactListFjr,
                                          callback: function ($$v) {
                                            _vm.contactListFjr = $$v
                                          },
                                          expression: "contactListFjr",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "iconfont",
                                          on: { click: _vm.showMailListFjr },
                                        },
                                        [_vm._v("")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("mail-list-dialog", {
                                    ref: "mailListDialogFjr",
                                    on: { confirmSelect: _vm.confirmSelectFjr },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.sjrChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sjrChecked", $$v)
                              },
                              expression: "form.sjrChecked",
                            },
                          },
                          [_vm._v("收件人")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.sjrChecked,
                                expression: "form.sjrChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      "popper-class": "z-select-cls",
                                    },
                                    model: {
                                      value: _vm.form.sjrOper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "sjrOper", $$v)
                                      },
                                      expression: "form.sjrOper",
                                    },
                                  },
                                  _vm._l(_vm.getOptions(), function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticClass: "filed-item" },
                                [
                                  _c("span", { staticClass: "label" }),
                                  _c(
                                    "div",
                                    { staticClass: "input-wrapper" },
                                    [
                                      _c("input-tag", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.contactListSjr,
                                          callback: function ($$v) {
                                            _vm.contactListSjr = $$v
                                          },
                                          expression: "contactListSjr",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "iconfont",
                                          on: { click: _vm.showMailListSjr },
                                        },
                                        [_vm._v("")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("mail-list-dialog", {
                                    ref: "mailListDialogSjr",
                                    on: { confirmSelect: _vm.confirmSelectSjr },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.themeChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "themeChecked", $$v)
                              },
                              expression: "form.themeChecked",
                            },
                          },
                          [_vm._v("主题")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.themeChecked,
                                expression: "form.themeChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      "popper-class": "z-select-cls",
                                    },
                                    model: {
                                      value: _vm.form.themeOper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "themeOper", $$v)
                                      },
                                      expression: "form.themeOper",
                                    },
                                  },
                                  _vm._l(
                                    _vm.getOptions(",3,4,"),
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "20",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.form.themeValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "themeValue", $$v)
                                    },
                                    expression: "form.themeValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "padding-top": "5px",
                                  "padding-left": "12px",
                                },
                                attrs: { span: 4 },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.themeCheckedDXX,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "themeCheckedDXX",
                                          $$v
                                        )
                                      },
                                      expression: "form.themeCheckedDXX",
                                    },
                                  },
                                  [_vm._v("区分大小写")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.sizeChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sizeChecked", $$v)
                              },
                              expression: "form.sizeChecked",
                            },
                          },
                          [_vm._v("邮件大小")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.sizeChecked,
                                expression: "form.sizeChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "97.8%" },
                                    attrs: {
                                      placeholder: "请输入",
                                      size: "mini",
                                      oninput:
                                        "value=value.replace(/[^\\d]/g,'')",
                                    },
                                    model: {
                                      value: _vm.form.minSize,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "minSize", $$v)
                                      },
                                      expression: "form.minSize",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("KB")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 1 } },
                              [_c("span", [_vm._v("至")])]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "97.8%" },
                                    attrs: {
                                      placeholder: "请输入",
                                      size: "mini",
                                      oninput:
                                        "value=value.replace(/[^\\d]/g,'')",
                                    },
                                    model: {
                                      value: _vm.form.maxSize,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "maxSize", $$v)
                                      },
                                      expression: "form.maxSize",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("KB")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.attachChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attachChecked", $$v)
                              },
                              expression: "form.attachChecked",
                            },
                          },
                          [_vm._v("附件")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.attachChecked,
                                expression: "form.attachChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      "popper-class": "z-select-cls",
                                    },
                                    model: {
                                      value: _vm.form.attachOper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "attachOper", $$v)
                                      },
                                      expression: "form.attachOper",
                                    },
                                  },
                                  _vm._l(_vm.attachOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { staticClass: "border-name" }),
                  _c("div", { staticClass: "rule-zx" }, [
                    _c("div", { staticClass: "m-tit" }, [_vm._v("执行规则")]),
                    _c("div", { staticClass: "m-mod rule-btn-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "rule-btn",
                          class: [_vm.form.type === 1 ? "cur" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.switchRule(1)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            attrs: { name: "wancheng", size: "small" },
                          }),
                          _c("span", { staticClass: "rule-txt" }, [
                            _vm._v("普通规则"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "rule-btn",
                          class: [_vm.form.type === 2 ? "cur" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.switchRule(2)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            attrs: { name: "wancheng", size: "small" },
                          }),
                          _c("span", { staticClass: "rule-txt" }, [
                            _vm._v("彻底删除"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.type === 1,
                            expression: "form.type===1",
                          },
                        ],
                        staticClass: "m-mod",
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.yddChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "yddChecked", $$v)
                              },
                              expression: "form.yddChecked",
                            },
                          },
                          [_vm._v("移动到")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.yddChecked,
                                expression: "form.yddChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      "popper-class": "z-select-cls",
                                    },
                                    model: {
                                      value: _vm.form.yddOper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "yddOper", $$v)
                                      },
                                      expression: "form.yddOper",
                                    },
                                  },
                                  _vm._l(_vm.foldOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.type === 1,
                            expression: "form.type===1",
                          },
                        ],
                        staticClass: "m-mod",
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.markReadedChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "markReadedChecked", $$v)
                              },
                              expression: "form.markReadedChecked",
                            },
                          },
                          [_vm._v("将邮件标记为已读")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.autoSendChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "autoSendChecked", $$v)
                              },
                              expression: "form.autoSendChecked",
                            },
                          },
                          [_vm._v("自动转发")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.autoSendChecked,
                                expression: "form.autoSendChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "filed-item" },
                                [
                                  _c("span", { staticClass: "label" }),
                                  _c(
                                    "div",
                                    { staticClass: "input-wrapper" },
                                    [
                                      _c("input-tag", {
                                        attrs: {
                                          "offset-y": _vm.offsetY,
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.contactListZF,
                                          callback: function ($$v) {
                                            _vm.contactListZF = $$v
                                          },
                                          expression: "contactListZF",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "iconfont",
                                          on: { click: _vm.showMailListZF },
                                        },
                                        [_vm._v("")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("mail-list-dialog", {
                                    ref: "mailListDialogZF",
                                    on: { confirmSelect: _vm.confirmSelectZF },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.autoReplyChecked,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "autoReplyChecked", $$v)
                              },
                              expression: "form.autoReplyChecked",
                            },
                          },
                          [_vm._v("自动回复")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.autoReplyChecked,
                                expression: "form.autoReplyChecked",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 1,
                                    autosize: true,
                                  },
                                  model: {
                                    value: _vm.form.autoReplyValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "autoReplyValue", $$v)
                                    },
                                    expression: "form.autoReplyValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.stopDealOtherFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stopDealOtherFlag", $$v)
                              },
                              expression: "form.stopDealOtherFlag",
                            },
                          },
                          [_vm._v("停止处理其他规则")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { staticClass: "border-name" }),
                  _c("div", { staticClass: "other" }, [
                    _c("div", { staticClass: "m-tit" }, [_vm._v("其他")]),
                    _c(
                      "div",
                      { staticClass: "m-mod" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.form.executeFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "executeFlag", $$v)
                              },
                              expression: "form.executeFlag",
                            },
                          },
                          [_vm._v("保存成功后对历史邮件执行新规则")]
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.executeFlag,
                                expression: "form.executeFlag",
                              },
                            ],
                            staticClass: "row-pad",
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("en-select", {
                                  attrs: {
                                    placeholder: "请选择",
                                    data: _vm.foldOptions,
                                    props: _vm.vlProps,
                                    filterable: true,
                                    multiple: true,
                                    clearable: true,
                                    align: "left",
                                    "data-mode": "value",
                                  },
                                  model: {
                                    value: _vm.form.executeFolders,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "executeFolders", $$v)
                                    },
                                    expression: "form.executeFolders",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "btn-cls", on: { click: _vm.save } }, [
                _c("div", { staticClass: "btn-txt" }, [_vm._v("确定")]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }