<!--
 * @Author: zoujp
 * @Date: 2021-03-16 11:29:15
 * @LastEditTime: 2021-04-19 10:23:20
 * @LastEditors: Please set LastEditors
 * @Description: 执行规则
 * @FilePath: \mailbox\src\components\MailSetting\execRule.vue
-->
<!--
 * @Author: zoujp
 * @Date: 2021-03-15 14:25:06
 * @LastEditTime: 2021-03-16 11:23:32
 * @LastEditors: Please set LastEditors
 * @Description: 条件设置弹窗
 * @FilePath: \mailbox\src\components\MailSetting\AddCondition.vue
-->
<template>
  <el-dialog title="需重新执行规则的文件夹" width="700px" v-if="visiable" :visible.sync="visiable" :close-on-click-modal="false" @close="closeDialog">
    <div class="content-wrap" v-loading="loading">
      <el-form ref="form" :model="form" label-width="60px" size="mini">
         <el-form-item label="选文件夹">
          <en-select
            v-model="form.executeFolders"
            placeholder="请选择"
            :data="foldOptions"
            :props="vlProps"
            :filterable="true"
            :multiple="true"
            :clearable="true"
            align="left"
            data-mode="value"
          >
          </en-select>
         </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="btn-cls" @click="save">
        <div class="btn-txt">确定</div>
      </div>
    </span>
  </el-dialog>
</template>
<script>
/* eslint-disable */
import { mailSettingService } from "@/api/mailBox/mailSetting";

export default {
  name: "execRule",
  props: {
    checkResult: {
      type: Array,
      default() {
        return [];
      }
    },
    foldOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  inject: ["$home"],
  data() {
    return {
      visiable: false,
      form: {
        executeFolders: []
      },
      vlProps: {
        label: "label",
        value: "value"
      },
      loading: false
    };
  },
  watch: {
    visiable(val) {
      if(val){
        this.resetForm();
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    // 重置form
    resetForm() {
      Object.assign(this.form, this.$options.data().form);
    },
    // 关闭弹窗
    closeDialog() {
      this.visiable = false;
    },
    // 保存
    async save() {
      if (!this.form.executeFolders.length) {
        this.$message({
          message: "请选择文件夹",
          type: "warning"
        });
        return true;
      }
      const dis_arr = this.checkResult.filter( item => item.enableFlag === 1);
      if(dis_arr.length){
        this.$message({
          message: "您的规则已被禁用，请先启用",
          type: "warning"
        });
        return true;
      }
      try {
        this.loading = true;
        const params = {
          ids: (item => {
            var ids = [];
            this.checkResult.forEach(ele => {
              ids.push(ele.id);
            });
            return ids.join(",");
          })(),
          executeFolders: this.form.executeFolders.join(",")
        }
        console.log(params);
        await mailSettingService.executeMailFilter( params );
        this.loading = false;
        this.visiable = false;
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.$home.saveCallback();
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
  .z-select-cls{
    .el-select-dropdown__item{
      font-size: 12px !important;
    }
  }
  .z-filter-sel-cls{
    display: flex;
    align-items: center;
    .sel-txt {
      margin-left: 5px;
    }
  }
</style>
<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 4px;
  .el-dialog__title{
    font-size: 14px;
    font-weight: bold;
  }
  .el-dialog__body{
    max-height: 500px;
    overflow-y: auto;
  }
}
.content-wrap{
  /deep/ .el-select{
    width: 100%;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-cls {
    width: 70px;
    height: 30px;
    background: #3e90fe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
