<!--
 * @Author: zoujp
 * @Date: 2021-03-15 11:47:06
 * @LastEditTime: 2024-01-24 14:20:57
 * @LastEditors: linshanfeng
 * @Description: 邮箱过滤
 * @FilePath: \mailbox\src\views\MailSetting\FilterMail.vue
-->
<template>
  <div class="filter-wrap">
    <div class="filter-top">
      <div class="top-inner">
        <div class="top-tit">邮件过滤</div>
        <div class="top-but">
          <en-button icon="shoudongzhihangguize" iconColor="#3e90fe" color="#636c78"  text @click="rule">手动执行规则</en-button>
          <en-button icon="qiyong" iconColor="#25c393" color="#636c78"  text @click="enable">启用</en-button>
          <en-button icon="tingyong" iconColor="#f76b6c" color="#636c78"  text @click="stop">禁用</en-button>
          <en-button icon="shanchu" iconColor="#f76b6c" color="#636c78"  text @click="del">删除</en-button>
          <en-button icon="add" iconSize="12" @click="add">添加</en-button>
        </div>
      </div>
    </div>
    <div class="filter-center">
      <en-table :data="dataRecords" ref="enTable" :loading="loading" :height-config="{ bottomHeight }" @select-change="handleSelectChange" auto-resize>
        <en-table-column type="index-selection"></en-table-column>
        <en-table-column title="规则名称" field="name" width="200px">
          <template v-slot="{ row }">
              <div :class="[row.enableFlag===0?'':'disabled-row']">
                {{ row.name }}
              </div>
          </template>
        </en-table-column>
        <en-table-column title="条件" field="name">
          <template v-slot="{ row }">
              <div :class="[row.enableFlag===0?'':'disabled-row']">
                {{ getFormatCon(row) }}
              </div>
          </template>
        </en-table-column>
        <en-table-column title="动作" field="name">
          <template v-slot="{ row }">
              <div :class="[row.enableFlag===0?'':'disabled-row']">
                  {{ getFormatAct(row) }}
              </div>
          </template>
        </en-table-column>
        <template #hoverRow="{row, rowIndex}">
          <en-expand-buttons
            minNumber="3"
            :data="getButtonData(row)"
            @button-click="
              handleButtonClick({
                row,
                rowIndex,
                btnData: arguments[1],
                btnIndex: arguments[0]
              })"
          >
          </en-expand-buttons>
        </template>
      </en-table>
      <en-pagination :page-model="pageModel" @change="handlePageChanged" v-show="dataRecords.length">
        <template slot="left">
          <div v-show="checkResult.length" class="selection">
            已选: <span>{{checkResult.length}}</span> 条数据
            <en-button text type="primary" @click="clearSelection">清空</en-button>
          </div>
        </template>
      </en-pagination>
    </div>
    <AddCondition ref="popAddCondition" :editData="editData" :operators="operators" :attachOptions="attachOptions" :foldOptions="foldOptions"></AddCondition>
    <ExecRule ref="popExecRule" :checkResult="checkResult" :foldOptions="foldOptions"></ExecRule>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import AddCondition from "../../components/MailSetting/AddCondition.vue";
import ExecRule from "../../components/MailSetting/execRule.vue";
import { mailSettingService } from "@/api/mailBox/mailSetting";

export default {
  name: "FilterMail",
  components: { AddCondition, ExecRule },
  data() {
    return {
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        totalPages: 0
      },
      dataRecords: [], // 列表数据
      checkResult: [], // 选中行数据
      seParam: {},
      editData: null,
      operators: [  // 操作符
        {
          value: 1,
          label: "等于"
        },
        {
          value: 2,
          label: "不等于"
        },
        {
          value: 3,
          label: "包含"
        },
        {
          value: 4,
          label: "不包含"
        }
      ],
      attachOptions: [ // 附件
        {
          value: 0,
          label: "带附件"
        },
        {
          value: 1,
          label: "不带附件"
        }
      ],
      foldOptions: [ // 文件夹
        {
          value: 1,
          label: "收件箱"
        },
        {
          value: 3,
          label: "草稿箱"
        },
        {
          value: 2,
          label: "已发送"
        },
        {
          value: 4,
          label: "已删除"
        },
        {
          value: 5,
          label: "垃圾箱"
        }
      ]
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
    bottomHeight() {
      if (this.dataRecords.length) {
        return 68;
      }
      return 18;
    }
  },
  provide() {
    return {
      $home: this
    };
  },
  methods: {
     // 清除选中的项目
    clearSelection() {
      this.checkResult = [];
      this.$refs.enTable.setAllSelection(false);
    },
    // 根据类型获取行悬停按钮
    getButtonData(row) {
      return [
        {
          icon: "bianji-liebiao",
          name: "编辑",
          iconColor: "#25c393"
        },
        {
          icon: !row.enableFlag? "jinyong-liebiao" : "qiyong-liebiao",
          name: !row.enableFlag? "禁用" : "启用",
          iconColor: !row.enableFlag? "#a8b5c6" : "#3e90fe"
        },
        {
          icon: "shanchu-liebiao",
          name: "删除",
          iconColor: "#f76b6e"
        }
      ];
    },
    // 获取操作符1，附件2，文件夹的中文名3
    getName(type,val) {
      let re = "";
      const arr = this[type];
      for(let i=0;i<arr.length;i++){
        if(val === arr[i].value){
          re = arr[i].label;
          break;
        }
      }
      return re;
    },
    getContactNames(arr) {
      arr = arr || [];
      const re = [];
      arr.forEach(itm => {
        re.push(itm.name || itm.mail);
      });
      return re.join(",");
    },
    // 格式化条件
    getFormatCon(row) {
      let re = [];
      row.conditionValue.forEach(itm => {
        if(itm.enableFlag === 0){
          const field = itm.field;
          if(field === "sender"){
            re.push(`发件人${this.getName("operators",itm.opertor)} “${this.getContactNames(itm.value)}”`);
          }else if(field === "receiver"){
            re.push(`收件人${this.getName("operators",itm.opertor)} “${this.getContactNames(itm.value)}”`);
          }else if(field === "title"){
            const dxx = itm.caseSensitivFlag === 0? "区分大小写": "不区分大小写";
            re.push(`主题${this.getName("operators",itm.opertor)} “${itm.value}” （${ dxx }）`);
          }else if(field === "mailSize"){
            re.push(`邮件大小 “${itm.minSize}KB 至 ${itm.maxSize}KB”`);
          }else if(field === "attachFlag"){
            re.push(`附件 “${this.getName("attachOptions",itm.value)}”`);
          }
        }
      });
      return re.join("；");
    },
    // 格式化动作
    getFormatAct(row) {
      // let re = [row.type === 1? "普通规则" : "彻底删除" ];
      let re = [];
      row.executeValue.forEach(itm => {
        if(itm.enableFlag === 0){
          const field = itm.field;
          if(field === "move"){
            if(row.type === 1){
              re.push(`移动到 “${this.getName("foldOptions",itm.value)}”`);
            }
          }else if(field === "read"){
            if(row.type === 1){
              re.push(`将邮箱标记为已读`);
            }
          }else if(field === "autoTrans"){
            re.push(`自动转发 “${this.getContactNames(itm.value)}”`);
          }else if(field === "autoReply"){
            re.push(`自动回复 “${itm.value}”`);
          }
        }
      });
      if(row.stopDealOtherFlag === 0){
        re.push("停止处理其他规则");
      }
      return re.join("；");
    },
    // 分页器事件
    handlePageChanged({ pageSize, pageNo }) {
      console.log(pageSize, pageNo);
      this.$set(this.pageModel, "pageNo", pageNo);
      this.$set(this.pageModel, "pageSize", pageSize);
      this.getList();
    },
    handleButtonClick({ row, rowIndex, btnData, btnIndex }) {
      console.log(rowIndex, btnData, btnIndex);
      // 编辑
      if (btnIndex === 0) {
        this.editData = row;
        this.$refs.popAddCondition.visiable = true;
      }
      // 禁用
      if (btnIndex === 1) {
        if(row.enableFlag === 0){
          this.stop(row.id);
        }else{
          this.enable(row.id);
        }
      }
      // 删除
      if (btnIndex === 2) {
        this.delRow(row.id);
      }
    },
    // 选中行
    handleSelectChange({ selection }) {
      this.checkResult = selection;
    },
    buttonDataMethod({ row }) {
      return this.buttonData.slice(0, row.id);
    },
    // 获取规则列表
    async getList() {
      try {
        this.checkResult = [];
        this.loading = true;
        const rsp = await mailSettingService.queryMailFilter(Object.assign({},this.pageModel));
        this.loading = false;
        this.dataRecords =  rsp.records || [];
        this.pageModel.pageNo = rsp.pageNo;
        this.pageModel.pageSize = rsp.pageSize;
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
      } catch (error) {
        this.loading = false;
      }
    },
    // 删除行
    delRow(id) {
      this.del(id)
    },
    // 删除
    del(id) {
      if(!id && !this.checkResult.length){
        this.$message({
          message: "请选择规则",
          type: "warning"
        });
        return true;
      }
      const _this = this;
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        closeOnClickModal: false,
        message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
          h("en-icon", { attrs: { name: "tishi", color: "#F76B6A" } }),
          h("div", { style: { marginLeft: "10px" } }, [ h("p", {}, "确定确定删除该规则？")])
        ]),
        confirmButtonClass: "mail-btn-error",
        async callback(action) {
          if (action === "confirm") {
            _this.delSure(id || _this.getSelectedIds());
          }
        }
      });
    },
    // 删除确认
    async delSure(ids) {
      try {
        this.loading = true;
        const params = {
          ids: ids
        };
        await mailSettingService.deleteMailFilter(params);
        this.loading = false;
        this.$message({
          message: "删除成功",
          type: "success"
        });
        this.getList();
      } catch (error) {
        console.log("error");
      }
    },
    // 添加
    add() {
      this.editData = null;
      this.$refs.popAddCondition.visiable = true;
    },
    // 手动执行规则
    rule() {
      if(!this.checkResult.length){
        this.$message({
          message: "请选择规则",
          type: "warning"
        });
        return false;
      }
      this.$refs.popExecRule.visiable = true;
    },
    // 启用
    async enable(id) {
      if(!id && !this.checkResult.length){
        this.$message({
          message: "请选择规则",
          type: "warning"
        });
      }else{
        try {
          this.loading = true;
          const ids = id || this.getSelectedIds();
          const rsp = await mailSettingService.enableMailFilter({ ids: ids,enableFlag: 0 });
          this.loading = false;
          this.getList();
        } catch (error) {
          this.loading = false;
        }
      }
    },
    // 停用
    async stop(id) {
      if(!id && !this.checkResult.length){
        this.$message({
          message: "请选择规则",
          type: "warning"
        });
      }else{
        try {
          this.loading = true;
          const ids = id || this.getSelectedIds();
          const rsp = await mailSettingService.enableMailFilter({ ids: ids, enableFlag: 1 });
          this.loading = false;
          this.getList();
        } catch (error) {
          this.loading = false;
        }
      }
    },
    // 获取选择的ids
    getSelectedIds() {
      var ids = [];
      this.checkResult.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    // 新增编辑回调
    saveCallback() {
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-wrap{
  .filter-top{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e8ecf2;
    box-sizing: border-box;
    .top-inner{
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items:center;
      .top-tit{
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
      .top-but{
       display: flex;
       justify-content: flex-end;
       align-items: center;
       /deep/ .en-button span{
         font-size: 12px;
       }
      }
    }
  }
  .selection {
    font-size: 12px;
    color: #606060;
    span {
      color: #4695DF;
    }
    .en-button {
      margin-left: 10px;
      padding-bottom: 2px;
    }
  }
  .filter-center{
    margin-top: 10px;
    .disabled-row{
      color: #d3d3d3;
    }
    .page-count {
        display: flex;
        margin-left: 20px;
        align-items: center;
        font-size: 12px;
        .page-clear {
          padding-left: 15px;
          color: #3e90fe;
          cursor: pointer;
        }
        .page-selected {
          span {
            color: #3e90fe;
          }
        }
      }
    /deep/ .en-expand-buttons .en-multi-button .buttons{
      padding-right:13px;
    }
  }
}
</style>
