<!--
 * @Author: zoujp
 * @Date: 2021-03-15 14:25:06
 * @LastEditTime: 2021-04-21 18:00:25
 * @LastEditors: Please set LastEditors
 * @Description: 条件设置弹窗
 * @FilePath: \mailbox\src\components\MailSetting\AddCondition.vue
-->
<template>
  <el-dialog title="条件设置"  width="700px" v-if="visiable" :visible.sync="visiable" :close-on-click-modal="false" @close="closeDialog">
    <div class="content-wrap" id="content-wrap" v-loading="loading">
      <el-form ref="form" :model="form" label-width="60px" size="mini">
        <el-form-item label="规则名称">
          <el-row>
            <el-col :span="20"><el-input v-model="form.name" maxlength="20" placeholder="请输入"></el-input></el-col>
            <el-col :span="4" style="padding-left:12px;"><el-checkbox v-model="form.enableFlag">启用</el-checkbox></el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="border-name"></el-form-item>
        <div class="mail-dd">
          <div class="m-tit">邮件到达时</div>
          <div class="m-mod">
            <el-checkbox v-model="form.fjrChecked">发件人</el-checkbox>
            <el-row class="row-pad" v-show="form.fjrChecked">
              <el-col :span="8">
                <el-select v-model="form.fjrOper" placeholder="请选择" popper-class="z-select-cls">
                  <el-option
                    v-for="item in getOptions()"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="16">
                <div class="filed-item">
                  <span class="label"></span>
                  <div class="input-wrapper">
                    <input-tag v-model="contactListFjr" placeholder="请输入"></input-tag>
                    <span class="iconfont" @click="showMailListFjr">&#xe6b3;</span>
                  </div>
                  <mail-list-dialog ref="mailListDialogFjr" @confirmSelect="confirmSelectFjr"></mail-list-dialog>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.sjrChecked">收件人</el-checkbox>
            <el-row class="row-pad" v-show="form.sjrChecked">
              <el-col :span="8">
                <el-select v-model="form.sjrOper" placeholder="请选择" popper-class="z-select-cls">
                  <el-option
                    v-for="item in getOptions()"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="16">
                 <div class="filed-item">
                  <span class="label"></span>
                  <div class="input-wrapper">
                    <input-tag v-model="contactListSjr" placeholder="请输入"></input-tag>
                    <span class="iconfont" @click="showMailListSjr">&#xe6b3;</span>
                  </div>
                  <mail-list-dialog ref="mailListDialogSjr" @confirmSelect="confirmSelectSjr"></mail-list-dialog>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.themeChecked">主题</el-checkbox>
            <el-row class="row-pad" v-show="form.themeChecked">
              <el-col :span="8">
                <el-select v-model="form.themeOper" placeholder="请选择" popper-class="z-select-cls">
                  <el-option
                    v-for="item in getOptions(',3,4,')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-input v-model="form.themeValue" maxlength="20" placeholder="请输入"></el-input>
              </el-col>
               <el-col :span="4" style="padding-top:5px;padding-left:12px;">
                <el-checkbox v-model="form.themeCheckedDXX">区分大小写</el-checkbox>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.sizeChecked">邮件大小</el-checkbox>
            <el-row class="row-pad" v-show="form.sizeChecked">
               <el-col :span="8">
                <el-input
                  placeholder="请输入"
                  v-model="form.minSize"
                  size="mini"
                  oninput = "value=value.replace(/[^\d]/g,'')"
                  style="width:97.8%;"
                >
                   <span slot="suffix" style="margin-right:10px;">KB</span>
                </el-input>
              </el-col>
              <el-col class="line" :span="1"><span>至</span></el-col>
              <el-col :span="8" style="margin-left:10px;">
                 <el-input
                  placeholder="请输入"
                  v-model="form.maxSize"
                  size="mini"
                  oninput = "value=value.replace(/[^\d]/g,'')"
                  style="width:97.8%;"
                >
                   <span slot="suffix" style="margin-right:10px;">KB</span>
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.attachChecked">附件</el-checkbox>
            <el-row class="row-pad" v-show="form.attachChecked">
              <el-col :span="8">
                <el-select v-model="form.attachOper" placeholder="请选择" popper-class="z-select-cls">
                  <el-option
                    v-for="item in attachOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-form-item class="border-name"></el-form-item>
        <div class="rule-zx">
          <div class="m-tit">执行规则</div>
          <div class="m-mod rule-btn-wrap">
            <div class="rule-btn" :class="[form.type===1?'cur':'']" @click="switchRule(1)">
              <en-icon name="wancheng" size="small"></en-icon>
              <span class="rule-txt">普通规则</span>
            </div>
            <div class="rule-btn" :class="[form.type===2?'cur':'']" @click="switchRule(2)">
              <en-icon name="wancheng" size="small"></en-icon>
              <span class="rule-txt">彻底删除</span>
            </div>
          </div>
          <div class="m-mod" v-show="form.type===1">
            <el-checkbox v-model="form.yddChecked">移动到</el-checkbox>
            <el-row class="row-pad" v-show="form.yddChecked">
              <el-col :span="24">
                <el-select v-model="form.yddOper" placeholder="请选择" popper-class="z-select-cls">
                  <el-option
                    v-for="item in foldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod" v-show="form.type===1">
            <el-checkbox v-model="form.markReadedChecked">将邮件标记为已读</el-checkbox>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.autoSendChecked">自动转发</el-checkbox>
            <el-row class="row-pad" v-show="form.autoSendChecked">
              <el-col :span="24">
                 <div class="filed-item">
                  <span class="label"></span>
                  <div class="input-wrapper">
                    <input-tag :offset-y="offsetY" v-model="contactListZF" placeholder="请输入"></input-tag>
                    <span class="iconfont" @click="showMailListZF">&#xe6b3;</span>
                  </div>
                  <mail-list-dialog ref="mailListDialogZF" @confirmSelect="confirmSelectZF"></mail-list-dialog>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.autoReplyChecked">自动回复</el-checkbox>
            <el-row class="row-pad" v-show="form.autoReplyChecked">
              <el-col :span="24">
                 <el-input type="textarea" :rows="1" v-model="form.autoReplyValue" :autosize="true"></el-input>
              </el-col>
            </el-row>
          </div>
          <div class="m-mod">
            <el-checkbox v-model="form.stopDealOtherFlag">停止处理其他规则</el-checkbox>
          </div>
        </div>
        <el-form-item class="border-name"></el-form-item>
        <div class="other">
          <div class="m-tit">其他</div>
          <div class="m-mod">
            <el-checkbox v-model="form.executeFlag">保存成功后对历史邮件执行新规则</el-checkbox>
            <el-row class="row-pad" v-show="form.executeFlag">
              <el-col :span="24">
                <en-select
                  v-model="form.executeFolders"
                  placeholder="请选择"
                  :data="foldOptions"
                  :props="vlProps"
                  :filterable="true"
                  :multiple="true"
                  :clearable="true"
                  align="left"
                  data-mode="value"
                >
                </en-select>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="btn-cls" @click="save">
        <div class="btn-txt">确定</div>
      </div>
    </span>
  </el-dialog>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import InputTag from "../../components/MailSend/InputTag/InputTag";
import MailListDialog from "../../components/MailSend/MailListDialog";

export default {
  name: "addCondition",
  components: {
    InputTag,
    MailListDialog
  },
  props: {
    editData: {
      type: Object,
      default() {
        return null;
      }
    },
    operators: {
      type: Array,
      default() {
        return [];
      }
    },
    attachOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    foldOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  inject: ["$home"],
  data() {
    return {
      visiable: false,
      contactListFjr: [],
      contactListSjr: [],
      contactListZF: [],
      offsetY: 0,
      form: {
        id: "",
        name: "",
        enableFlag: true,

        fjrChecked: false,
        fjrOper: 1,
        fjrValue: [],

        sjrChecked: false,
        sjrOper: 1,
        sjrValue: [],

        themeChecked: false,
        themeOper: 3,
        themeValue: "",
        themeCheckedDXX: false,

        sizeChecked:false,
        maxSize:"",
        minSize:"",

        attachChecked: false,
        attachOper: 0,

        yddChecked: false,
        yddOper: 1,

        autoSendChecked: false,
        autoSendValue: [],

        markReadedChecked: false,
        stopDealOtherFlag: false,
        type: 1,

        autoReplyChecked: false,
        autoReplyValue: "",
        
        executeFlag: false,
        executeFolders: []
      },
      defaultProps: {
        label: "name",
        value: "id"
      },
      vlProps: {
        label: "label",
        value: "value"
      },
      concactData: [],
      loading: false
    };
  },
  watch: {
    visiable(val) {
      if(val){
        this.resetForm();
        this.getContactData();
        if(this.editData){
          this.initEditData();
        }
        this.$nextTick(() => {
          document.querySelector(".el-dialog__body").addEventListener("scroll", this.addScrollFn)
        })
      } else {
        document.querySelector(".el-dialog__body").removeEventListener("scroll", this.addScrollFn)
      }
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    addScrollFn(e) {
      this.offsetY = -e.target.scrollTop
    },
    showMailListFjr() {
      this.$refs.mailListDialogFjr.show();
    },
    showMailListSjr() {
      this.$refs.mailListDialogSjr.show();
    },
    showMailListZF() {
      this.$refs.mailListDialogZF.show();
    },
    confirmSelectFjr(list) {
      const formatList = list.map((item) => ({
        mailAccount: item.address,
        mailName: item.name,
        userId: item.id
      })).filter((item) => !this.contactListFjr.find((contact) => contact.mailAccount === item.mailAccount));
      this.contactListFjr = [...this.contactListFjr, ...formatList];
    },
    confirmSelectSjr(list) {
      const formatList = list.map((item) => ({
        mailAccount: item.address,
        mailName: item.name,
        userId: item.id
      })).filter((item) => !this.contactListSjr.find((contact) => contact.mailAccount === item.mailAccount));
      this.contactListSjr = [...this.contactListSjr, ...formatList];
    },
    confirmSelectZF(list) {
      const formatList = list.map((item) => ({
        mailAccount: item.address,
        mailName: item.name,
        userId: item.id
      })).filter((item) => !this.contactListZF.find((contact) => contact.mailAccount === item.mailAccount));
      this.contactListZF = [...this.contactListZF, ...formatList];
    },
    // 重置form表单数据
    resetForm() {
      Object.assign(this.form, this.$options.data().form);
    },
    // 获取条件，执行数据
    getValue(key,field) {
      let obj = {};
      for(let i=0;i<this.editData[key].length;i++){
        if(this.editData[key][i].field === field){
          obj = this.editData[key][i];
          break;
        }
      }
      return obj;
    },
    // 获取联系人id数组
    getContactId(arr){
      let re = [];
      arr.forEach(item => {
        re.push(item.accountId);
      });
      return re;
    },
    // 编辑初始化整理数据
    initEditData() {
      const ed = this.editData;
      this.form.name = ed.name;
      this.form.enableFlag = !ed.enableFlag;

      let fjrObj = this.getValue("conditionValue","sender");
      this.form.fjrChecked = !fjrObj.enableFlag;
      this.form.fjrOper = fjrObj.opertor;
      // this.form.fjrValue = this.getContactId(fjrObj.value);
      this.form.fjrValue = fjrObj.value.map(v => { return {...v, mailAccountId: v.accountId, mailAccount: v.mail, mailName: v.name }});
      this.contactListFjr = this.form.fjrValue;
      
      let sjrObj = this.getValue("conditionValue","receiver");
      this.form.sjrChecked = !sjrObj.enableFlag;
      this.form.sjrOper = sjrObj.opertor;
      // this.form.sjrValue = this.getContactId(sjrObj.value);
      this.form.sjrValue = sjrObj.value.map(v => { return {...v, mailAccountId: v.accountId, mailAccount: v.mail, mailName: v.name }});
      this.contactListSjr = this.form.sjrValue;

      let themeObj = this.getValue("conditionValue","title");
      this.form.themeChecked = !themeObj.enableFlag;
      this.form.themeOper = themeObj.opertor;
      this.form.themeValue = themeObj.value;
      this.form.themeCheckedDXX = !themeObj.caseSensitivFlag;

      let sizeObj = this.getValue("conditionValue","mailSize");
      this.form.sizeChecked = !sizeObj.enableFlag;
      this.form.maxSize = sizeObj.maxSize;
      this.form.minSize = sizeObj.minSize;

      let attachObj = this.getValue("conditionValue","attachFlag");
      this.form.attachChecked = !attachObj.enableFlag;
      this.form.attachOper = attachObj.value;
      
      let yddObj = this.getValue("executeValue","move");
      this.form.yddChecked = !yddObj.enableFlag;
      this.form.yddOper = yddObj.value;

      let autoObj = this.getValue("executeValue","autoTrans");
      this.form.autoSendChecked = !autoObj.enableFlag;
      // this.form.autoSendValue = this.getContactId(autoObj.value);
      this.form.autoSendValue = autoObj.value.map(v => { return {...v, mailAccountId: v.accountId, mailAccount: v.mail, mailName: v.name }});
      this.contactListZF = this.form.autoSendValue;

      let readObj = this.getValue("executeValue","read");
      this.form.markReadedChecked = !readObj.enableFlag;

      this.form.stopDealOtherFlag = !ed.stopDealOtherFlag;
      this.form.type = ed.type;

      let replyObj = this.getValue("executeValue","autoReply");
      this.form.autoReplyChecked = !replyObj.enableFlag;
      this.form.autoReplyValue = replyObj.value;

      this.form.executeFlag = !ed.executeFlag;
      
      if(ed.executeFolders){
        this.form.executeFolders = (()=>{
          const efds = (ed.executeFolders).split(",");
          const r = efds.map((item)=>{ 
            item = parseInt(item); 
            return item; 
          })
          return r;
        })();
      }
      this.$forceUpdate();
    },
    // 获取头像首字
    getFirstName(name) {
      return (name || '').split("")[0] || '';
    },
    // 切换执行规则
    switchRule(type) {
      this.form.type = type;
    },
    // 获取对应的操作符
    getOptions(str) {
      str = str || '1,2,3,4';
      let options = cloneDeep(this.operators);
      let res = [];
      options.forEach((item)=>{
        let v = ','+ item.value +',';
        if(str.indexOf(item.value)>-1){
          res.push(item);
        }
      });
      return res;
    },
    // 关闭弹窗
    closeDialog() {
      this.visiable = false;
    },
    // 获取联系人数据
    async getContactData() {
      try {
        const params = {
          pageNo: 1,
          pageSize: 99999
        };
        const rsp = await mailSettingService.searchContact(params);
        this.concactData = rsp.records || [];
        this.concactData.forEach(ele=>{
          ele.text = ele.name;
          ele.name = ele.name +"（"+ele.address+"）";
        });
        console.log(arr);
      } catch (error) {
      }
    },
    // 整理联系人后台需要的数据格式
    getContactList(arr) {
      var re = [];
      for(let i=0;i<arr.length;i++){
        for(let j=0;j<this.concactData.length;j++){
          if(arr[i] === this.concactData[j].id){
            re.push({
              name:this.concactData[j].text,
              mail:this.concactData[j].address,
              accountId:this.concactData[j].id
            });
            continue;
          }
        }
      }
      return re;
    },
    sleep(delay) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, delay)
      })
    },
    // 保存
    async save() {
      if (!this.form.name) {
        this.$message({
          message: "规则名称不能为空",
          type: "warning"
        });
        return true;
      }
      await this.sleep(300);
      try {
        this.loading = true;
        const fm = this.form;
        const This = this;
        const params = {
          name:this.form.name,
          enableFlag: this.form.enableFlag?0:1,
          type:this.form.type,
          stopDealOtherFlag: this.form.stopDealOtherFlag?0:1,
          executeFlag: this.form.executeFlag?0:1,
          executeFolders: this.form.executeFolders.join(","),
          conditionValue: (() => {
            let re = [];
            re.push({
              enableFlag: fm.fjrChecked?0:1,
              field: "sender",
              opertor: fm.fjrOper,
              value: This.contactListFjr.map(v => { return {...v, accountId: v.mailAccountId, mail: v.mailAccount, name:v.mailName }}) 
              // value: This.getContactList(fm.fjrValue)
            });
            re.push({
              enableFlag: fm.sjrChecked?0:1,
              field: "receiver",
              opertor: fm.sjrOper,
              // value: This.getContactList(fm.sjrValue)
              value: This.contactListSjr.map(v => { return {...v, accountId: v.mailAccountId, mail: v.mailAccount, name:v.mailName }}) 
            });
            re.push({
              enableFlag: fm.themeChecked?0:1,
              field: "title",
              opertor: fm.themeOper,
              value: fm.themeValue,
              caseSensitivFlag:fm.themeCheckedDXX?0:1
            });
            re.push({
              enableFlag: fm.sizeChecked?0:1,
              field: "mailSize",
              maxSize: fm.maxSize,
              minSize: fm.minSize,
              unit: "KB"
            });
            re.push({
              enableFlag: fm.attachChecked?0:1,
              field: "attachFlag",
              value: fm.attachOper
            });
            console.log(JSON.stringify(re,null,2));
            return JSON.stringify(re);
          })(),
          executeValue:(() => {
            let re = [];
            re.push({
              enableFlag: fm.yddChecked?0:1,
              field: "move",
              value: fm.yddOper
            });
            re.push({
              enableFlag: fm.markReadedChecked?0:1,
              field: "read"
            });
            re.push({
              enableFlag: fm.autoSendChecked?0:1,
              field: "autoTrans",
              // value: This.getContactList(fm.autoSendValue)
              value: This.contactListZF.map(v => { return {...v, accountId: v.mailAccountId, mail: v.mailAccount, name:v.mailName }}) 
            });
            re.push({
              enableFlag: fm.autoReplyChecked?0:1,
              field: "autoReply",
              value: fm.autoReplyValue
            });
            console.log(JSON.stringify(re,null,2));
            return JSON.stringify(re);
          })()
        };
        if(this.editData && this.editData.id){
          params.id = this.editData.id;
        }
        await mailSettingService.saveMailFilter( params );
        this.loading = false;
        this.visiable = false;
        this.contactListFjr = [];
        this.contactListSjr = [];
        this.contactListZF = [];
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.$home.saveCallback();
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
  .z-select-cls{
    .el-select-dropdown__item{
      font-size: 12px !important;
    }
  }
  .z-filter-sel-cls{
    display: flex;
    align-items: center;
    .sel-txt {
      margin-left: 5px;
    }
  }
</style>
<style lang="scss" scoped>
/deep/ .el-dialog {
  border-radius: 4px;
  .el-dialog__title{
    font-size: 14px;
    font-weight: bold;
  }
  .el-dialog__body{
    max-height: 450px;
    overflow-y: auto;
  }
}
.content-wrap {
  .border-name{
    border-top: 1px solid #e8ecf2;
  }
  .mail-dd{
    padding-bottom: 10px;
  }
  .rule-zx{
    padding-bottom: 10px;
  }
  .other{
    padding-bottom: 10px;

  }
  .m-tit{
    font-size: 12px;
    font-weight: bold;
    color: #333;
  }
  .m-mod{
    padding:10px 0;
  }
  .rule-btn-wrap{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .rule-btn{
      width: 135px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      background-color: #f5f8fc;
      cursor: pointer;
      .en-icon{
        display: none;
      }
      .rule-txt{
        margin-left: 5px;
        font-size: 12px;
      }
      &:first-child{
        margin-right: 10px;
      }
      &.cur{
        background-color: #e9faf4;
        .en-icon{
          display: block;
          color: #25c393;
        }
        .rule-txt{
          color: #25c393;
        }
      }
    }
  }
  .row-pad{
    padding-top: 5px;
  }
  /deep/ .el-select{
    width: 95%;
  }
  .line{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    font-size: 12px;
    color: #636c78;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-cls {
    width: 70px;
    height: 30px;
    background: #3e90fe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
.filed-item {
    display: flex;
    align-items: center;
    .label {
      color: #333333;
      font-size: 12px;
      margin-right: 0px;
    }
    .input-wrapper {
      display: flex;
      flex: 1;
      line-height: 32px;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      z-index: 100;
      .iconfont {
        position: relative;
        display: inline-block;
        width: 32px;
        font-size: 18px;
        line-height: 32px;
        color: #a9b5c6;
        cursor: pointer;
        text-align: center;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 22px;
          background: #e8ecf2;
          top: 4px;
          left: -4px;
        }
      }
    }
  }
  /deep/ .vue-input-tag-wrapper .new-tag{
    padding-left: 10px;
  }
</style>
